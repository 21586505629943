import React from 'react'
import { graphql } from 'gatsby'
import { Link as GatsbyLink } from 'gatsby'
import { Box, Button, Heading, Stack } from '@chakra-ui/react'
import LayoutPublic from "../components/layouts/layoutPublic"
// import ReactMarkdown from 'react-markdown'
import Seo from '../components/Seo/';

const PageTemplate = (props) => {
  return (
    <LayoutPublic lang={process.env.GATSBY_LANG}>

      <Seo
        title={
          props.data.page.title
        }
        // description={
        //   description[process.env.GATSBY_LANG]
        // }
        slug={props.data.page.slug}
      />

      <Box
        px={{ base: 0, md: "2rem", lg: "2rem", xxl: "10rem" }}
        py={{ base: 0, md: "1rem", lg: "1rem", xxl: "2rem" }}
      >
        <Stack spacing={{ base: "2rem", lg: "3rem" }} mx='auto' maxW="800px" my="2rem" borderRadius="lg" background='white' p={{ base: '2rem', lg: '3rem' }}>
          <Box>
            <Button as={GatsbyLink} variant="outline" to='../'>
              ← Retour
            </Button>
          </Box>
          <Heading color='blue.900' mb="2rem" as='h1'>
            {props.data.page.title}
          </Heading>
          <Box className="wysiwyg">
            {/* <ReactMarkdown source={props.data.page.childContentfulPageBodyTextNode.body} /> */}
            {/* <WysiwygContent data={ props.data.page.childContentfulPostContentRichTextNode.json } /> */}
          </Box>
        </Stack>
      </Box>
    </LayoutPublic>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query page(
      #$slug: String!,
      $contentfulID:  String!,
      #$otherLang: String!
    ){
    page: contentfulPage(contentful_id: {eq: $contentfulID}) {
      slug
      title
      childContentfulPageBodyTextNode { body }
    }
  }
`